import styled, { keyframes, css } from 'styled-components';
import { tint } from 'polished';
import ICoresData from '../../context/ICoresData';

import { FiMenu, FiClock, FiChevronLeft, FiChevronRight } from 'react-icons/fi';

interface CoresProps {
  cores: ICoresData;
}

interface ColumnProps {
  expanded: boolean;
}

interface WaitProps {
  categoryColor: string;
}

interface VisionProps {
  active: boolean;
}

interface AgendaSlotProps {
  cor?: string;
  agendado: boolean;
}

interface CarrouselProps {
  currentPage: number;
  itemsPerPage: number;
  totalItems: number;
}

interface CarrouselItemProps {
  selected: boolean;
}

const detectBrowser = () => {
  const userAgent = navigator.userAgent;

  if (userAgent.includes('Chrome')) {
    return 'chrome';
  } else if (userAgent.includes('Firefox')) {
    return 'firefox';
  } else if (userAgent.includes('Safari')) {
    return 'safari';
  } else {
    return 'other'; // Para outros navegadores
  }
};

const getScrollbarStyles = (browser: string, props: CoresProps) => {
  if (browser !== 'firefox') {
    return css`
      /* Estilizando o Scrollbar para Chrome/Safari */
      &::-webkit-scrollbar {
        width: 5px;
        height: 5px; /* Para barras de rolagem horizontais */
      }

      &::-webkit-scrollbar-thumb {
        cursor: pointer;
        background-color: ${props.cores.secundaria}; /* Cor do "thumb" */
        border-radius: 10px;
        border: 3px solid transparent;
      }

      &::-webkit-scrollbar-track {
        background-color: #f0f0f0; /* Cor do "track" */
      }

      /* Remove as setas do scrollbar em WebKit */
      &::-webkit-scrollbar-button {
        display: none; /* Remove as setas do scrollbar */
      }
    `;
  } else {
    return css`
      /* Estilizando o Scrollbar para Firefox */
      scrollbar-width: thin; /* Largura do scrollbar: "auto" ou "thin" */
      scrollbar-color: ${props.cores.secundaria} #f0f0f0; /* Cor do thumb e do track */
    `;
  }
};

const IconStyle = css`
  cursor: pointer;

  height: 40px;
  width: 20px;

  margin-bottom: 15px;
  align-self: center;
`;

const carrousselButtonStyles = css`
  width: 18px;
  height: 18px;
  box-sizing: bor;
  color: var(--color-blue);
  cursor: pointer;
`;

export const ArrowLeftIcon = styled(FiChevronLeft)`
  ${carrousselButtonStyles}
`;

export const ArrowRightIcon = styled(FiChevronRight)`
  ${carrousselButtonStyles}
`;

export const MenuIcon = styled(FiMenu)`
  ${IconStyle}
`;

export const ClockIcon = styled(FiClock)`
  ${IconStyle}
`;

export const Container = styled.div`
  height: 100vh;
  width: 100%;

  overflow: hidden;
`;

export const AgendaContainer = styled.div`
  display: flex;
  flex-direction: row;

  width: 100%;

  margin-top: 75px;
  height: calc(100% - 75px);

  @media (max-width: 610px) {
    margin-top: 60px;
    height: calc(100% - 60px);
  }
`;

export const SideColumn = styled.div<ColumnProps & CoresProps>`
  display: flex;
  flex-direction: column;
  width: ${props => (props.expanded ? '25%' : '2%')};
  transition: width 0.4s ease;

  border-right: 1px solid lightgray;

  padding: 15px;

  overflow-y: auto;
  overflow-x: hidden;

  ${props => getScrollbarStyles(detectBrowser(), props)};
`;

export const HeaderSideColumn = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const AgendaColumn = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;

  transition: width 0.5s ease-in-out;
`;

const surgirDeCima = keyframes`
  from {
    opacity: 0;
    transform: translateY(-100px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

// const fadeIn = keyframes`
//   from {
//       opacity: 0;
//       transform: translateY(-20px);
//   }
//   to {
//       opacity: 1;
//       transform: translateY(0);
//   }
// `;

export const Header = styled.header<CoresProps>`
  /* fixar na tela */
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100;

  padding: 10px;

  background: ${props => props.cores.primaria};
  /* width: auto; */
  flex: 1;

  animation: ${surgirDeCima} 1s;

  height: 75px;
  @media (max-width: 610px) {
    height: 60px;
    padding: 5px;
  }
`;

export const HeaderContent = styled.div<CoresProps>`
  /* dá uma alinhada no centro no header content */
  /*margin: 0 auto;*/
  display: flex;
  align-items: center;
  justify-content: space-between;

  /* estiliza a primeira imagem: a logo */
  a {
    div {
      background-color: #f8f8f8;
      border-radius: 8px;
    }

    img {
      height: 50px;
      width: 50px;

      @media (max-width: 610px) {
        width: 40px;
        height: 40px;
      }

      cursor: pointer;
    }
  }
`;

export const Profile = styled.div<CoresProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  img {
    height: 50px;
    width: 50px;

    border-radius: 100%;

    @media (max-width: 610px) {
      width: 35px;
      height: 35px;
    }

    margin-right: 10px;
  }
`;

export const ProfileContent = styled.div<CoresProps>`
  div {
    padding: 2px;

    button {
      display: flex;
      align-items: center;

      background-color: transparent;
      border: none;
      color: ${props => props.cores.secundaria};
      font-size: 20px;

      svg {
        margin-right: 5px;
      }

      @media (max-width: 610px) {
        font-size: 15px;
      }
    }
  }
`;

export const NewButton = styled.div<CoresProps>`
  width: 100%;
  height: 40px;
  display: flex;

  button {
    background: ${props => props.cores.secundaria};
    border-radius: 8px 8px 8px 8px;
    border: none;
    flex: 1;

    height: 30px;
    color: #fff;

    transition: color 0.3s;
    transition: background-color 0.1s;

    &:hover {
      background-color: ${props => props.cores.secundariaShade};
    }

    &:active {
      background-color: ${props => props.cores.primaria};
    }

    div {
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        height: 20px;
        width: 20px;
        margin-right: 10px;
      }

      span {
        font-size: 18px;
      }
    }
  }
`;

export const Calendar = styled.div<CoresProps>`
  .DayPicker {
    color: ${props => props.cores.secundaria};
    font-size: 13px;
    background: #f5f5f5;
    border-radius: 10px;
    box-shadow: 0px 0px 3px 1px rgba(0, 0, 0, 0.1);
  }

  .DayPicker-wrapper {
    padding-bottom: 0;
  }

  .DayPicker,
  .DayPicker-Month {
    width: 100%;
    height: 100%;
  }

  .DayPicker-Month {
    border-collapse: separate;
    border-spacing: 4px;
    margin: 8px;
  }

  .DayPicker-Day {
    width: 25px;
    height: 25px;
  }

  .DayPicker-Day--available:not(.DayPicker-Day--outside) {
    background: #ffffff;
    border-radius: 10px;
    color: ${props => props.cores.texto};
  }

  .DayPicker:not(.DayPicker--interactionDisabled)
    .DayPicker-Day:not(.DayPicker-Day--disabled):not(
      .DayPicker-Day--selected
    ):not(.DayPicker-Day--outside):hover {
    background: ${props => props.cores.textoClaro};
  }

  .DayPicker-Day--today {
    font-weight: normal;
  }

  .DayPicker-Day--disabled {
    color: ${props => props.cores.secundaria} !important;
    background: transparent !important;
  }

  .DayPicker-Day--selected {
    background: ${props => props.cores.secundaria} !important;
    border-radius: 10px;
    color: ${props => props.cores.textoClaro} !important;
  }
`;

export const VisionsRow = styled.div<CoresProps & VisionProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  padding: 12px;

  border-bottom: 1px solid lightgray;
`;

export const ControlDiv = styled.div<CoresProps>`
  display: flex;
  flex-direction: column;
  margin-left: 10px;

  p {
    color: ${props => props.cores.secundaria};
    font-size: 20px;
    font-weight: 600;
  }
`;

export const ControlButtons = styled.div<CoresProps>`
  display: flex;
  flex-direction: row;
  gap: 3px;
  align-items: center;
  justify-content: center;
  margin-bottom: 5px;

  button {
    display: flex;
    align-items: center;

    background: ${props => props.cores.secundaria};
    border: none;
    flex: 1;

    height: 30px;
    color: #fff;

    transition: color 0.3s;
    transition: background-color 0.1s;

    &:hover {
      background-color: ${props => props.cores.secundariaShade};
    }

    &:active {
      background-color: ${props => props.cores.primaria};
    }
  }

  .todayButton {
    padding: 10px;
  }

  .previewButton {
    padding: 5px;
    border-radius: 8px 0px 0px 8px;
  }

  .nextButton {
    padding: 5px;
    border-radius: 0px 8px 8px 0px;
  }
`;

export const WaitList = styled.div<CoresProps>`
  display: flex;
  flex-direction: column;

  border-radius: 10px;
  border: 1px solid ${props => props.cores.primaria};

  p {
    margin: 10px 0px 0px 10px;
  }

  button {
    height: 40px;
    margin: 0px 10px 0px 10px;

    div {
      span {
        font-weight: 600;
        font-size: 15px;
      }
    }
  }
`;

export const WaitListTitle = styled.div<CoresProps>`
  border-radius: 10px 10px 0px 0px;
  margin-bottom: 10px;

  font-size: 14px;
  color: #fff;

  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 30px;
  background-color: ${props => props.cores.primaria};
`;

export const WaitingPacient = styled.div<WaitProps>`
  cursor: pointer;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  height: 35px;

  font-size: 14px;

  margin: 10px;
  padding: 5px;

  border-radius: 8px;
  background-color: ${props => props.categoryColor};
  color: #fff;

  img {
    height: 30px;
    width: 30px;
    object-fit: contain;
  }
`;

export const AgendaRow = styled.div<CoresProps>`
  display: flex;
  flex-direction: column;
  padding: 10px;
  overflow-y: auto;
  overflow-x: auto;

  div {
    display: flex;
    flex-direction: row;
  }

  ${props => getScrollbarStyles(detectBrowser(), props)};
`;

export const AgendaHour = styled.p`
  margin-right: 10px;
`;

export const AgendaSlot = styled.div<AgendaSlotProps>`
  padding: 5px;

  height: 75px;
  border-bottom: 1px solid lightgray;

  background-color: ${props =>
    props.agendado ? tint(0.8, props.cor ? props.cor : '#f8f8f8') : '#f8f8f8'};
  border-left: ${props => (props.agendado ? `5px solid ${props.cor}` : '')};

  width: 100%;

  p {
    font-size: 18px;
  }
`;

export const AgendaSlotContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: start;
  justify-content: space-between;
  width: 100%;

  p {
    margin-top: 5px;
    font-weight: 600;
    font-size: 18px;
  }

  img {
    width: 65px;
    height: 65px;
    @media (max-width: 610px) {
      width: 30px;
      height: 30px;
    }

    object-fit: contain;
  }
`;

export const CarrouselContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 100%;
`;

export const Carrousel = styled.div`
  display: flex;
  overflow: hidden; /* Esconder produtos que estão fora da área visível, que é manipulada pelo transform no eixo X */
  width: 100%;
  position: relative;
`;

export const CarrouselTrack = styled.div<CarrouselProps>`
  display: flex;
  transition: transform 0.5s ease-in-out; /* Animação de deslizar */
  transform: ${props =>
    `translateX(-${
      props.currentPage * 100
    }%)`}; /* Mover os itens com base na página atual. Mexe no eixo X 100 multiplicado pela página atual. Esse 100 representa 100% da página atual do carrossel. É como se fosse deixar 100% da track para fora do eixo X*/
  width: ${props =>
    `${
      props.totalItems * (100 / props.itemsPerPage)
    }%`}; /* Ajustar a largura total da track do carrossel com base no número de itens, para que apareça os itemsPerPage especificados em cada página. 100 representa novamente a largura total do container, divindo ela pelos itens por página e multiplicando pelo total da pra saber a largura que a track tem que ter */
`;

export const CarrouselItem = styled.div<CoresProps & CarrouselItemProps>`
  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;
  border-radius: 30px;
  border: none;
  background-color: ${props =>
    props.selected
      ? tint(0.6, props.cores.secundaria ? props.cores.secundaria : 'white')
      : 'lightgray'};
  color: ${props => (props.selected ? props.cores.secundaria : '#a0a0a0')};
  font-weight: 600;

  transition: background-color, color 0.5s ease;

  height: 30px;
  margin: 0px 2.5px 0px 2.5px;

  min-width: calc(
    16.666% - 5px
  ); /*Cálculo da lágura mínima como são 6 itens por página 16.666% (100 / 6) e desconta os 5px de margin (2.5px da esquerda e 2.5px da direita)*/
  text-align: center;

  svg {
    margin-left: 5px;
  }
`;

export const CarrouselButton = styled.button`
  border: none;
  background-color: transparent;
  cursor: pointer;
  z-index: 1;

  /*Se o número de páginas for igual à página atual é passada a propriedade disabled como true, para fazer o botão de passar a página do carrossel sumir*/
  &:disabled {
    visibility: collapse;
  }
`;

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

export const TableHeader = styled.th`
  background-color: #f2f2f2;
  border: 1px solid #ddd;
  padding: 8px;
  text-align: center;
`;

export const TableCell = styled.td`
  border: 1px solid #ddd;
  padding: 8px;
  text-align: center;

  height: 65px;
`;

export const TableRow = styled.tr`
  &:nth-child(even) {
    background-color: #f9f9f9;
  }
`;
