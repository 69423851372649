import React, { useState, useEffect, useCallback } from 'react';
import api from '../../services/api';
import DayPicker, { DayModifiers } from 'react-day-picker';
import 'react-day-picker/lib/style.css';

import { useConfigs } from '../../context/ConfigsContext';
import { useAuth } from '../../context/AuthContext';
import { Link, useHistory } from 'react-router-dom';

import {
  FiLogOut,
  FiPlusCircle,
  FiChevronLeft,
  FiChevronRight,
  FiUser,
} from 'react-icons/fi';

import defaultAvatar from '../../assets/default-avatar.png';
import logoImg from '../../assets/mundo-aba-logo.png';
import logoPas from '../../assets/logo padre albino saude.png';
import logoUnimed from '../../assets/logo unimed.png';

import {
  Container,
  AgendaContainer,
  AgendaColumn,
  Header,
  HeaderContent,
  Profile,
  ProfileContent,
  NewButton,
  Calendar,
  VisionsRow,
  ControlDiv,
  ControlButtons,
  WaitList,
  WaitListTitle,
  WaitingPacient,
  MenuIcon,
  ClockIcon,
  AgendaRow,
  AgendaHour,
  AgendaSlot,
  AgendaSlotContent,
  HeaderSideColumn,
  SideColumn,
  Carrousel,
  CarrouselTrack,
  CarrouselButton,
  CarrouselContainer,
  CarrouselItem,
  ArrowLeftIcon,
  ArrowRightIcon,
  Table,
  TableCell,
  TableHeader,
  TableRow,
} from './styles';

interface PacienteEsperando {
  id: number;
  name: string;
  category: string;
  color: string;
  plano: string;
}

interface Profissionais {
  id: number;
  name: string;
}

interface Horarios {
  id: number;
  hora: string;
}

interface HorariosAgendados {
  id: number;
  hora: string;
  paciente: string;
  data: Date;
  categoria: string;
  categoriaCor: string;
  profissionalNome: string;
  plano: string;
}

const profissionais: Profissionais[] = [
  { id: 45, name: 'Sérgio Tiago Carlos' },
  { id: 32, name: 'Arthur Manoel Novaes' },
  { id: 77, name: 'Jennifer Daniela Souza' },
  { id: 97, name: 'Benício Henry da Silva' },
  { id: 31, name: 'Isabela Laís Ayla' },
  { id: 62, name: 'Stella Bárbara Santos' },
  { id: 14, name: 'Nicole Eduarda Freitas' },
  { id: 2, name: 'Maria Alessandra Ferreira' },
  { id: 19, name: 'Francisco Eduardo Pires' },
  { id: 37, name: 'Clarice Larissa dos Santos' },
  { id: 44, name: 'Marcos Manoel Castro' },
];

//const waiting: PacienteEsperando[] = [];
const listaEspera: PacienteEsperando[] = [
  {
    id: 1,
    name: 'Caleb Marcos Brito',
    category: 'Psicólogo',
    color: '#00FA9A',
    plano: 'pas',
  },
  {
    id: 2,
    name: 'Amanda Sophia Farias',
    category: 'Fonoaudiólogo',
    color: '#9932CC',
    plano: 'pas',
  },
  {
    id: 3,
    name: 'Jorge Bernardo Leandro Lima',
    category: 'Clínico Geral',
    color: '#FF4500',
    plano: 'unimed',
  },
  {
    id: 4,
    name: 'Bianca Catarina Benedita Melo',
    category: 'Terapia Ocupacional',
    color: '#FFD700',
    plano: 'pas',
  },
];

const slots: Horarios[] = [
  {
    id: 1,
    hora: '07:00',
  },
  {
    id: 2,
    hora: '08:00',
  },
  {
    id: 3,
    hora: '09:00',
  },
  {
    id: 4,
    hora: '10:00',
  },
  {
    id: 5,
    hora: '11:00',
  },

  {
    id: 6,
    hora: '12:00',
  },
  {
    id: 7,
    hora: '13:00',
  },
  {
    id: 8,
    hora: '14:00',
  },
  {
    id: 9,
    hora: '15:00',
  },
  {
    id: 10,
    hora: '16:00',
  },
  {
    id: 11,
    hora: '17:00',
  },
  {
    id: 12,
    hora: '18:00',
  },
  {
    id: 13,
    hora: '19:00',
  },
];

const slotsAgendados: HorariosAgendados[] = [
  {
    id: 1,
    hora: '09:00',
    paciente: 'Hugo Kauê Monteiro',
    data: new Date('10/14/2024'),
    categoria: 'Psicólogo',
    categoriaCor: '#00FA9A',
    profissionalNome: 'Sérgio Tiago Carlos',
    plano: 'pas',
  },
  {
    id: 2,
    hora: '11:00',
    paciente: 'Enzo Gabriel Araújo',
    data: new Date('10/14/2024'),
    categoria: 'Psicólogo',
    categoriaCor: '#00FA9A',
    profissionalNome: 'Isabela Laís Ayla',
    plano: 'unimed',
  },
  {
    id: 3,
    hora: '09:00',
    paciente: 'Enzo Gabriel Araújo',
    data: new Date('10/14/2024'),
    categoria: 'Fonoaudiólogo',
    categoriaCor: '#9932CC',
    profissionalNome: 'Arthur Manoel Novaes',
    plano: 'unimed',
  },
  {
    id: 4,
    hora: '13:00',
    paciente: 'Enzo Gabriel Araújo',
    data: new Date('10/14/2024'),
    categoria: 'Terapia Ocupacional',
    categoriaCor: '#FFD700',
    profissionalNome: 'Sérgio Tiago Carlos',
    plano: 'pas',
  },
  {
    id: 5,
    hora: '14:00',
    paciente: 'Enzo Gabriel Araújo',
    data: new Date('10/16/2024'),
    categoria: 'Clínico Geral',
    categoriaCor: '#FF4500',
    profissionalNome: 'Stella Bárbara Santos',
    plano: 'pas',
  },
  {
    id: 6,
    hora: '07:00',
    paciente: 'Lucca Leandro Isaac',
    data: new Date('10/17/2024'),
    categoria: 'Psicólogo',
    categoriaCor: '#00FA9A',
    profissionalNome: 'Stella Bárbara Santos',
    plano: 'unimed',
  },
  {
    id: 6,
    hora: '09:00',
    paciente: 'Luna Giovana Araújo',
    data: new Date('10/17/2024'),
    categoria: 'Fonoaudiólogo',
    categoriaCor: '#9932CC',
    profissionalNome: 'Stella Bárbara Santos',
    plano: 'pas',
  },
  {
    id: 7,
    hora: '07:00',
    paciente: 'Luna Giovana Araújo',
    data: new Date('10/18/2024'),
    categoria: 'Fonoaudiólogo',
    categoriaCor: '#9932CC',
    profissionalNome: 'Stella Bárbara Santos',
    plano: 'unimed',
  },
];

const Agenda: React.FC = () => {
  const { cores, lerConfig } = useConfigs();
  const { user, signOut } = useAuth();

  const history = useHistory();

  const diasDesabilitados = [{ daysOfWeek: [0, 6] }];

  const [profissionaisSelecionados, setProfissionaisSelecionados] = useState<
    number[]
  >([45]);
  const [datePickerExpandido, setDatePickerExpandido] = useState<boolean>(true);
  const [listaEsperaExpandida, setListaEsperaExpandida] =
    useState<boolean>(false);
  const [dataSelecionada, setDataSelecionada] = useState(new Date());
  const [logoDinamico, setLogoDinamico] = useState<string | undefined>(
    undefined,
  );

  const [currentPage, setCurrentPage] = useState(0);

  const itemsPerPage = 6;
  const totalPages = Math.ceil(profissionais.length / itemsPerPage); // Total de páginas. Pegando a largura total do carrossel que foi calculada e dividindo pela largura dos itens do carrossel

  const handleNext = useCallback(() => {
    // Se a página atual for menor que a última vai para a próxima
    if (currentPage < totalPages - 1) {
      setCurrentPage(currentPage + 1);
    }
  }, [currentPage, totalPages]);

  const handlePrev = useCallback(() => {
    // Se a página atual não for a primeira volta para a anterior
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  }, [currentPage]);

  const toggleSelecionado = (id: number) => {
    if (profissionaisSelecionados.includes(id)) {
      // Se o profissional já está selecionado, remove da lista
      setProfissionaisSelecionados(
        profissionaisSelecionados.filter(
          profissionalId => profissionalId !== id,
        ),
      );
    } else {
      // Se não estiver selecionado, adicionar à lista
      setProfissionaisSelecionados([...profissionaisSelecionados, id]);
    }
  };

  const resumeNome = (nomeCompleto: String) => {
    const nomes = nomeCompleto.trim().split(' ');

    if (nomes.length > 1) {
      return `${nomes[0]} ${nomes[1]}`;
    }
  };

  const estaDesabilitado = (date: Date) => {
    const diaSemana = date.getDay();
    return diasDesabilitados.some(desabilitado =>
      desabilitado.daysOfWeek?.includes(diaSemana),
    );
  };

  const vaiParaDiaAnterior = () => {
    let diaAnterior = new Date(dataSelecionada);
    diaAnterior.setDate(dataSelecionada.getDate() - 1);

    while (estaDesabilitado(diaAnterior)) {
      diaAnterior.setDate(diaAnterior.getDate() - 1);
    }

    setDataSelecionada(diaAnterior);
  };

  const vaiParaProximoDia = () => {
    let proximoDia = new Date(dataSelecionada);
    proximoDia.setDate(dataSelecionada.getDate() + 1);

    while (estaDesabilitado(proximoDia)) {
      proximoDia.setDate(proximoDia.getDate() + 1);
    }

    setDataSelecionada(proximoDia);
  };

  const vaiParaHoje = () => {
    let hoje = new Date();

    while (estaDesabilitado(hoje)) {
      hoje.setDate(hoje.getDate() + 1);
    }

    setDataSelecionada(hoje);
  };

  const listaProfissionaisOrdenada = useCallback(() => {
    const profissionaisOrdenados = [...profissionais].sort((a, b) => {
      const isASelected = profissionaisSelecionados.includes(a.id);
      const isBSelected = profissionaisSelecionados.includes(b.id);

      if (isASelected && !isBSelected) return -1;
      if (!isASelected && isBSelected) return 1;

      return a.name.localeCompare(b.name);
    });

    return profissionaisOrdenados;
  }, [profissionaisSelecionados]);

  const listaProfissionaisSelecionados = useCallback(() => {
    return listaProfissionaisOrdenada().filter(profissional =>
      profissionaisSelecionados.includes(profissional.id),
    );
  }, [profissionaisSelecionados, listaProfissionaisOrdenada]);

  const handleDateChange = useCallback((day: Date, modifiers: DayModifiers) => {
    if (modifiers.available && !modifiers.disabled) {
      setDataSelecionada(day);
    }
  }, []);

  const abrirProfile = useCallback(() => {
    history.push('/profile');
  }, [history]);

  // const estaAgendado = useCallback(
  //   (hora: string): HorariosAgendados | undefined => {
  //     return slotsAgendados.find(agendamento => {
  //       return (
  //         agendamento.data.toDateString() === dataSelecionada.toDateString() &&
  //         agendamento.hora === hora
  //       );
  //     });
  //   },
  //   [dataSelecionada],
  // );

  const agendadoProfissional = useCallback(
    (profissionalNome: string, hora: string) => {
      return slotsAgendados.find(agendamento => {
        return (
          agendamento.profissionalNome === profissionalNome &&
          agendamento.hora === hora &&
          agendamento.data.toLocaleDateString() ===
            dataSelecionada.toLocaleDateString()
        );
      });
    },
    [dataSelecionada],
  );

  useEffect(() => {
    let configLogo: string | undefined = lerConfig('LOGO');
    if (configLogo && configLogo !== '') {
      api
        .get<string>('/empresas/configs-caminho-arquivo', {
          params: {
            arquivo: configLogo,
          },
        })
        .then(response => {
          setLogoDinamico(response.data);
        });
    } else {
      configLogo = undefined;
    }
  }, [lerConfig]);

  return (
    <Container>
      <Header cores={cores}>
        <HeaderContent cores={cores}>
          <Link to="/home">
            <div>
              <img src={logoDinamico ? logoDinamico : logoImg} alt="Logo" />
            </div>
          </Link>

          {user.empresa.utiliza_login === 'S' && (
            <Profile cores={cores}>
              {/* <ul>
                <li>
                  <button type="button" onClick={abrirProfile}>
                    <FiUser />
                    <span>Perfil</span>
                  </button>
                </li>
                <li>
                  <button type="button" onClick={signOut}>
                    <FiPower />
                    <span>Sair</span>
                  </button>
                </li>
              </ul> */}
              <img
                src={!user.avatar_url ? defaultAvatar : user.avatar_url}
                alt={user.name}
              />
              <ProfileContent cores={cores}>
                <div>
                  <button type="button" onClick={abrirProfile}>
                    <FiUser />
                    <span>Perfil</span>
                  </button>
                </div>
                <div>
                  <button type="button" onClick={signOut}>
                    <FiLogOut />
                    <span>Sair</span>
                  </button>
                </div>
              </ProfileContent>
            </Profile>
          )}
        </HeaderContent>
      </Header>

      <AgendaContainer>
        <SideColumn expanded={datePickerExpandido} cores={cores}>
          {datePickerExpandido ? (
            <>
              <HeaderSideColumn>
                <MenuIcon
                  onClick={() => {
                    setDatePickerExpandido(!datePickerExpandido);
                  }}
                />
              </HeaderSideColumn>

              <Calendar cores={cores}>
                <DayPicker
                  weekdaysShort={['D', 'S', 'T', 'Q', 'Q', 'S', 'S']}
                  disabledDays={diasDesabilitados}
                  selectedDays={dataSelecionada}
                  month={dataSelecionada}
                  modifiers={{
                    available: { daysOfWeek: [1, 2, 3, 4, 5] },
                  }}
                  onDayClick={handleDateChange}
                  months={[
                    'Janeiro',
                    'Fevereiro',
                    'Março',
                    'Abril',
                    'Maio',
                    'Junho',
                    'Julho',
                    'Agosto',
                    'Setembro',
                    'Outubro',
                    'Novembro',
                    'Dezembro',
                  ]}
                />
              </Calendar>
            </>
          ) : (
            <MenuIcon
              onClick={() => {
                setDatePickerExpandido(!datePickerExpandido);
              }}
            />
          )}
        </SideColumn>

        <SideColumn expanded={listaEsperaExpandida} cores={cores}>
          {listaEsperaExpandida ? (
            <>
              <HeaderSideColumn>
                <ClockIcon
                  onClick={() => {
                    setListaEsperaExpandida(!listaEsperaExpandida);
                  }}
                />
              </HeaderSideColumn>

              <WaitList cores={cores}>
                <WaitListTitle cores={cores}>Lista de Espera</WaitListTitle>

                <NewButton cores={cores}>
                  <button>
                    <div>
                      <FiPlusCircle />
                      <span>Adicionar Paciente</span>
                    </div>
                  </button>
                </NewButton>

                {listaEspera.length > 0 ? (
                  <div>
                    {listaEspera.map(paciente => {
                      return (
                        <>
                          <WaitingPacient
                            title={paciente.category}
                            data-tip={paciente.category}
                            key={paciente.id}
                            categoryColor={paciente.color}
                          >
                            {resumeNome(paciente.name)}

                            {paciente.plano === 'pas' ? (
                              <img
                                src={logoPas}
                                alt="Logo Padre Albino Saúde"
                              />
                            ) : (
                              <img src={logoUnimed} alt="Logo Unimed" />
                            )}
                          </WaitingPacient>
                        </>
                      );
                    })}
                  </div>
                ) : (
                  <div>
                    <p>Nenhum paciente na lista de espera no momento</p>
                  </div>
                )}
              </WaitList>
            </>
          ) : (
            <ClockIcon
              onClick={() => {
                setListaEsperaExpandida(!listaEsperaExpandida);
              }}
            />
          )}
        </SideColumn>

        <AgendaColumn>
          <VisionsRow cores={cores} active={false}>
            <CarrouselContainer>
              <CarrouselButton
                className="prev"
                onClick={handlePrev}
                disabled={currentPage === 0}
              >
                <ArrowLeftIcon />
              </CarrouselButton>

              <Carrousel>
                <CarrouselTrack
                  currentPage={currentPage}
                  itemsPerPage={itemsPerPage}
                  totalItems={profissionais.length}
                >
                  {listaProfissionaisOrdenada().map(profissional => (
                    <CarrouselItem
                      key={profissional.id}
                      cores={cores}
                      onClick={() => toggleSelecionado(profissional.id)}
                      selected={profissionaisSelecionados.includes(
                        profissional.id,
                      )}
                    >
                      <p>{resumeNome(profissional.name)}</p>
                    </CarrouselItem>
                  ))}
                </CarrouselTrack>
              </Carrousel>

              <CarrouselButton
                className="next"
                onClick={handleNext}
                disabled={currentPage === totalPages - 1}
              >
                <ArrowRightIcon />
              </CarrouselButton>
            </CarrouselContainer>

            <ControlDiv cores={cores}>
              <ControlButtons cores={cores}>
                <button className="previewButton" onClick={vaiParaDiaAnterior}>
                  <FiChevronLeft />
                </button>

                <button className="todayButton" onClick={vaiParaHoje}>
                  Hoje
                </button>

                <button className="nextButton" onClick={vaiParaProximoDia}>
                  <FiChevronRight />
                </button>
              </ControlButtons>

              <p>
                {dataSelecionada.toLocaleDateString('pt-BR', {
                  day: '2-digit',
                  month: '2-digit',
                  year: 'numeric',
                })}
              </p>
            </ControlDiv>
          </VisionsRow>

          <AgendaRow cores={cores}>
            {profissionaisSelecionados.length === 1 ? (
              <>
                {slots.map(horario => {
                  const horarioAgendado = agendadoProfissional(
                    listaProfissionaisSelecionados()[0].name,
                    horario.hora,
                  );

                  return (
                    <div>
                      <AgendaHour>{horario.hora}</AgendaHour>
                      <AgendaSlot
                        key={horario.id}
                        agendado={!!horarioAgendado}
                        cor={horarioAgendado?.categoriaCor}
                      >
                        {horarioAgendado ? (
                          <AgendaSlotContent>
                            <p>{horarioAgendado.paciente}</p>
                            {horarioAgendado.plano === 'pas' ? (
                              <img
                                src={logoPas}
                                alt="Logo Padre Albino Saúde"
                              />
                            ) : (
                              <img src={logoUnimed} alt="Logo Unimed" />
                            )}
                          </AgendaSlotContent>
                        ) : (
                          ''
                        )}
                      </AgendaSlot>
                    </div>
                  );
                })}
              </>
            ) : (
              <Table>
                <thead>
                  <TableRow>
                    <TableHeader>Horários</TableHeader>
                    {listaProfissionaisSelecionados().map(profissional => (
                      <TableHeader key={profissional.id}>
                        {resumeNome(profissional.name)}
                      </TableHeader>
                    ))}
                  </TableRow>
                </thead>
                <tbody>
                  {slots.map(horario => (
                    <TableRow key={horario.id}>
                      <TableCell style={{ width: '40px' }}>
                        {horario.hora}
                      </TableCell>
                      {listaProfissionaisSelecionados().map(profissional => {
                        const Agendamento = agendadoProfissional(
                          profissional.name,
                          horario.hora,
                        );
                        return (
                          <TableCell
                            key={profissional.id}
                            style={{ minWidth: '200px' }}
                          >
                            {Agendamento ? 'Agendado' : ''}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  ))}
                </tbody>
              </Table>
            )}
          </AgendaRow>
        </AgendaColumn>
      </AgendaContainer>
    </Container>
  );
};

export default Agenda;
